.gx-app-login-wrap {
  background-image: url('../assets/images/loginBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}


.gx-app-logo-img {
  width: 130px;
  height: 40px;
}

.home {
  background-image: url('../assets/images/Shipping/banner2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}


::-webkit-scrollbar-thumb {
  background-color: darkgrey!important;
  outline: 1px solid slategrey!important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)!important;
}
::-webkit-scrollbar {
  width: 7px!important;
  background-color: rgba(146, 143, 143, 0.1)!important;
  /* -webkit-border-radius: 100px!important; */
}
.mapBackground {
  background-image: url('../assets/images/mapPageBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900');

/* body {
  font-weight: 100;
  font-family: 'Nunito', sans-serif !important;
  background: #f8f8f8;
  padding: 0 10px;
  overflow: hidden;
} */
body {
  margin: 0;
  /* font-size: 16px; */
  font-weight: 400;
  line-height: 1.5;
  color: #fcfcfc;
  text-align: left;
  background-color: white;
  font-family: 'Nunito', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.66em;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

.font-family-class {
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400;
}

/* home page css */
.wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.items i {
  font-size: 2rem;
  color: white;
  padding: 10px 15px;
  border: 1px solid rgb(226, 216, 216);
  box-shadow: 0 0 5px rgb(223, 218, 218);
  border-radius: 5px;
}

.items .fa-map-marker {
  background-color: #174ea6;
}

.items .fa-user-o {
  background-color: #ff5630;
}

.items .fa-cubes {
  background-color: #36b37e;

}

.items .fa-cog {
  background-color: #ff0000;
}

.mapWrapper {
  width: 98%;
}

/*@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");*/

body {
  padding: 0;
  margin: 0;
}

#app-site {
  display: flex;
  width: 100%;
  height: 100vh;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 60px;
  height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.loading {
  width: 120px;
}

.card-size {
  width: 280px;
  height: 140px;
}


.gx-main-content-wrapper {
  padding: 17px 17px 0;
  flex: 1 1;
}

/*warehouse css*/

/*Grid css close*/

.grid-section {
  min-width: 100px !important;
  min-height: 100px;
}

.grid-section table,
.grid-section td,
.grid-section th {
  border: 1px solid black;
}

.grid-section table {
  width: 100%;
  border-collapse: collapse;
}

.grid-section td {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  width: 45px;

}

.space_area {
  border: none !important;
  ;
}

.copy,
.paste {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.gridTable {
  margin: 0 0 20px 0;
}

.warehouseTableWrapper {
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.dashboardGrid {
  width: 100% !important;
}

.dttable {
  width: 100%;
}

.dttable,
.dttable tr,
.dttable td,
.dttable th {
  border: 1px solid #727376;
  border-collapse: collapse;
  padding: 5px 10px;
  text-align: center;
}

/* .warehouseTable{
  background-color: #dddddd;
} */
.warehouseTable,
.warehouseTable tr,
.warehouseTable td {
  border: 2px solid gray
}

.warehouseTable {
  border-spacing: 0px;


}

.border-left-hidden {
  border-left: hidden !important;
  /* background-color: #dddddd!important; */
}

.border-right-hidden {
  border-right: hidden !important;
  /* background-color: #dddddd!important; */
}

.border-top-hidden {
  border-top: hidden !important;
  /* background-color: #dddddd!important; */

}

.border-bottom-hidden {
  border-bottom: hidden !important;
  /* background-color: #dddddd!important; */
}

/* .border-left{
  border-left: 2px solid gray;
}
.border-right{
  border-right:  2px solid gray 
 
}
.border-top{
  border-top:    2px solid gray
  
}
.border-bottom{
  border-bottom: 2px solid gray 
} */

.dashboardTableWrapper {
  width: 100% !important;
}

.dashboardTable {
  max-width: 100% !important;
}

.dashboardTable Table {
  max-width: 100% !important;
  max-height: 100%;

}

/*# sourceMappingURL=loader.css.map*/

.formwrapp .gx-form-group {
  width: 300px
}

Model {
  background-color: white;
}

thead[class*="ant-table-thead"] th {
  font-weight: bold;
  font-family: 'Nunito', sans-serif !important;
  color: #242338 !important;
  font-size: 0.875rem;
  white-space: nowrap
}

.inventuary thead[class*="ant-table-thead"] th {
  font-weight: bold;
  font-family: 'Nunito', sans-serif !important;
  color: #242338 !important;
  font-size: 0.7rem;
  white-space: nowrap
}

.ant-table-content {
  font-size: .8rem !important;
  /* white-space: nowrap !important; */
  font-family: 'Nunito', sans-serif !important;
  padding: 1px;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  word-break: break-all;
}

/* .jobHeader  */
.buttonPaddding .ant-btn {
  margin-bottom: 0px!important;
}


/* .ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding:0 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 0 0!important;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
} */
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0px;
}

.ant-timeline-item:not(:last-child) {
  padding-bottom: 0px;
}

.viewJobDetal .column {
  padding: 5px 12px!important;
}

.ant-table-title {
  position: relative;
  top: 1px;
  padding: 10px 0;
  border-radius: 6px 6px 0 0;
}


.dashboard-table thead[class*="ant-table-thead"] th {
  font-weight: bold;
  font-family: 'Nunito', sans-serif !important;
  color: #242338 !important;
  font-size: 0.7rem;
  white-space: nowrap;
  padding: 10px;
}


.ant-drawer-right .ant-drawer-content-wrapper {
  width: 60vw !important;
  padding: 15px;
  background: #ffffff;
}

.ant-descriptions-title {
  margin-bottom: 5px;
  /* color: #535353; */
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}

.ant-table-thead>tr>th{
  /* text-transform: uppercase!important; */
  padding:7px 10px;
  /* text-align: center; */
  overflow-wrap: break-word;
}
.ant-table-tbody>tr>td {
  padding: 5px 7px;
  overflow-wrap: break-word;
}

.steps-content {
  margin-top: 24px;
 
}


.button_right_margin{
  margin-right:0!important;
}
.button_left_margin{
  margin-left:0!important;
}
.button_top_margin{
  margin-top:0!important;
}
.button_bottom_margin{
  margin-bottom:0!important;
}

/* .ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 0px 0px 0px 0px;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
} */
.steps-action {
  margin-top: 24px;
}
.ant-drawer-body {
  padding: 20px 0;
}
.dashboard-table .ant-table-content {
  font-size: .7rem !important;
  white-space: nowrap !important;
  font-family: 'Nunito', sans-serif !important;
  padding: 1px;
}



/*this is for bonded and domestic dashboard model css*/
.data-container span {
  color: #3c4858 !important;
}

.bar-section-multiple svg {
  width: 220px !important;
  height: 142px;
  margin: -37px 0px -46px 0px;
  padding: 0;
}

.bar-section-single svg {
  width: 220px !important;
  height: 142px;
  margin: -33px 0px -46px 0px;
  padding: 0;
}

/* .bar-section-single svg {
  width: 266px!important;
  height: 142px;
  margin: -26px 0 -42px 0px;
  padding: 0;
}
.bar-section-multiple svg {
  width: 277px!important;
  height: 142px;
  margin: -29px 0 -36px 0px;
  padding: 0;
} */

#printArea {
  margin-left: 5px,

}

.adjustContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

/*antd form*/

/* 
.ant-input {
  border-radius: 17px;
}

.ant-select-selection{
  border-radius: 17px;
} */

/*antd tooltip css*/
.ant-tooltip-inner {
  color: rgb(255, 255, 255);
  background-color: rgb(243, 49, 75);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: rgb(243, 49, 75);
}

.ant-tooltip-arrow::before {
  background-color: rgb(243, 49, 75);
}


.ant-form-item-control {
  position: relative;
  line-height: 15px;
  zoom: 1;
}

.area {
  position: relative;
  top: -7px;
}

/* time filter css start*/
.filterWrapperFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 0 0 0px;

}


.filterWrapper .input-field {
  width: 180px;
  padding: 7px 15px;
  margin: 0 0 12px 0;
  background-color: #ffffff;
  color: gray;
  display: inline-block;
  border: 1px solid rgb(180, 173, 173);
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  /* box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF; */
}

.filterWrapper .input-field[type="text"]::placeholder {
  font-size: .8rem;
}

.typeWrapper {
  margin: 0 0 0 10px;
}

.filterSubmit {
  background-color: #e75e2e;
  padding: 6px 10px 6px 10px !important;
  margin: 0 0 0 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.rangePicker {
  color: grey;
}

.placeholder {
  border: 1px solid gray;
  padding: 7px 10px;
  border-radius: 3px;
  color: rgb(39, 31, 31);
  min-width: 200px;
  /* text-align: left; */
  font-size: 0.9em;
}

.placeholderWrap {
  display: flex;
}

.placeholderWrap .placeholder:last-child {
  margin-left: 10px;
}

/* time filter css end*/


/*Dynamic form css start*/
.button {
  cursor: pointer;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15), 0 1px 3px 1px rgba(0, 0, 0, .15) !important;

}

.buttonY {
  cursor: pointer;
  border: none;
  color: white;
  padding: 2px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15), 0 1px 3px 1px rgba(0, 0, 0, .15) !important;

}

.box-shadow {
  box-shadow: 0 1px 3px 0 rgba(142, 142, 142, .2), 0 1px 1px 0 rgba(243, 243, 243, .14), 0 2px 1px -1px rgba(204, 204, 204, .12) !important;
  border-radius: 12px !important;
}

/* color background border commom css start*/
.text-color-yellow {
  color: #e75e2e;
}

.text-color-gray {
  color: #808080;
}

.text-color-white {
  color: white;
}

.text-color-black {
  color: rgb(0, 0, 0);
}

.background-yellow {
  background-color: #e75e2e;
}

.background-gray {
  background-color: gray;
}

.border-all-light-gray {
  border: 1px solid rgb(231 233 235);
}

.border-bottom-light-gray {
  border-bottom: 1px solid rgb(231 233 235);
}

.border-all-yellow {
  border: 1px solid #e75e2e;
  ;
}

.border-bottom-yellow {
  border: 1px solid #e75e2e;
  ;
}

/* color background border commom css end*/


.FormDataContainer {
  min-height: 70vh;
  padding: 20px 20px 15px 20px;
  overflow-y: auto;
}

.FormDataContainer .header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 15px 0;


}

.table-section {
  width: 100%;
  overflow: auto;
  /* max-height: 67vh; */
}

.table-section table {
  width: 100%;
  overflow: auto;
  /* max-height: 67vh; */
  border-radius: 12px !important;
}

.job-table {
  width: 100%;
  overflow-x: auto;
  /* max-height: 100vh; */
}

.table-section table,
.table-section table th,
.table-section table td {
  border: 1px solid rgb(231 233 235);
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.table-section table th{
  text-align: center;
  /* text-align: left; */
  padding:7px 10px;
  font-size: 16px;
  font-weight: 400;

}
.table-section table td {
  text-align: center;
  /* text-align: left; */
  padding:7px;
  font-size: 14px;
  font-weight: 400;

}

/* .ant-table-tbody > tr > td {
  border-bottom: 0px solid #f0f0f0;
  } */

.table-section table tr {
  width: 100% !important;
}

.table-section table th {
  color: rgb(0, 0, 0);
  /* font: bold; */
  background-color: #fafafa;
  font-weight: 700;
  font-family: Nunito, sans-serif !important;
  color: #242338 !important;
  font-size: 1rem;
  white-space: nowrap;
}



.table-section .tableBody td {
  color: #8F8F8F;
  font-size: .8rem;
}

.table-section .tableBody tr:hover {
  background-color: #fbece3;
  color: white;
  opacity: .7;
  transition: all .5s;
}

.table-section td {
  color: #8F8F8F;
  font-size: .9rem;
}

.table-section tr:hover {
  background-color: #fbece3;
  color: white;
  opacity: .7;
  transition: all .5s;
}

.table-section .tableBody2:hover {
  background-color: #fbece3;
  opacity: .7;
  transition: all .5s;
}



.table-section .addButton,
.table-section .viewButton {
  border: none;
  color: white;
  padding: 0x 0px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px !important;
  border-radius: 3px;
  margin: 3px 10px 3px 0
}

.table-section i {
  padding: 1px 3px;
}

.table-section .addButton {
  background-color: #e75e2e;
}

.table-section .viewButton {
  background-color: gray;

}
/* .addButton:hover, */

.scan-input-fields .scanButton {
    line-height: 1.299;
    position: relative;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: solid 1px #d9d9d9;
    padding: 0 15px;
    height: 36px;
    font-size: 14px;
    line-height: 34px;
    border-radius: 4px;
    margin-bottom: 15px;
    color: #545454;
    background-color: #fff;
    border-color: #d9d9d9;
    background-color: white;
}


.scan-input-fields button:focus {
  border: 1px solid #e75e2e!important;
  /* color: rgb(85, 84, 84); */
}

.tableDataView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;

}

.tableDataView .dataContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 5px 15px 5px 15px;
  font-family: 'Nunito', sans-serif !important;

}
.tableDataView .labelData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 5px 15px 5px 15px;
  font-family: 'Nunito', sans-serif !important;

}

.dataContainer .childHeader {
  font-size: .9rem;
  font-weight: bold;
  color: #242338;

}

.dataContainer .childData {
  font-size: .8rem;
  color: grey;

}

.elementWidth{
  width:17vw;
  /* width:22vw; */
  margin: 0 .5vw 0 0!important;
}
.elementWidthRadioCheckBox {
  width:100vw
}
.elementWidthShortText{
  width:100vw;
  break-after: always;
  
}
.elementWidthLongText{
  width:100vw;
  break-after: always;
}
/* .dataContainer .dataText {
  text-transform: capitalize !important;

} */

/*form-creater container*/
.FormCreaterContainer {
  border: 1px solid rgb(231 233 235);
  min-height: 80vh;
  padding: 10px 20px 15px 20px;
  margin: 0 0 20px 0;
}

.ComponentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.FormCreaterContainer .sideBar-section {
  width: 20%;
  min-height: 78vh;
  background-color: rgb(196, 200, 204);
  overflow-x: auto;
  overflow-y: auto;
  height: 447px;

}

.sideBar-section .sideBar-heading {
  display: table;
  margin-left: auto;
  margin-right: auto;
  /* color: black; */
  font-size: 25px;
  margin-top: 5px;

}

.sideBar-section .sideBar-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 10px 10px 10px;

}

.sideBar-data .sideBarItem {
  width: 100%;
  min-height: 40px;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; */
  cursor: pointer;

}

.draggableItem {
  margin-bottom: 10px;
  padding: 5px;
}

.sideBar-section .sideBar-data .draggableItem span {
  font-size: 16px;
  font-weight: 400
}

.sideBar-data .elementInfo {
  font-size: 18px;

}

.sideBar-section .sideBar-data .draggableItem span:nth-child(2n+1) {
  margin: 0 0 0 10px;
}

.sideBar-section .sideBar-data .draggableItem span:nth-child(2n) {
  margin: 0 0 0 10px;
}


.FormCreaterContainer .main-section {
  width: 79%;
  min-height: 78vh;
}

.FormCreaterContainer .main-section .button-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.go-back-buttom {

  margin: 5px 0 10px 0 !important;
  padding: 5px 15px;
}

.main-section .preview-button {
  margin: 5px;
}

.main-section .drop-section-heading {
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
}

.form-element-container {
  width: 100%;
  max-height: 447px;
  overflow-x: auto;
  overflow-y: auto;
}

button {
  cursor: pointer;
}

Button {
  cursor: pointer;
}

/* i{
  cursor: pointer;
} */
.saveTemplateButton {
  margin: 0 0 30px 30px !important;

}

.GoBack {
  margin: 0 0 30px 15px !important;
}

.drop-section-data {
  width: 100%;
  height: 100px;
}

.imageView {
  width: 100px;
  height: 100px;
}

.elementContainer {
  margin: 10px !important;
  padding: 10px 20px !important;
  color: #8F8F8F
}

.gridElementContainerClass {
  margin:5px 0px !important;
  padding: 0px !important;
  color: #8F8F8F
}

.renderElementContainer {
  margin: 10px !important;
  color: #8F8F8F
}

.hoverItem:hover {
  height: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 0 1px 3px 0 rgba(54, 49, 49, 0.2), 0 1px 1px 0 rgba(80, 72, 72, 0.14), 0 2px 1px -1px rgba(204, 204, 204, .12) !important;
}

.labelIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.labelIconContainer i {
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0 0 0 10px;
}

.elementContainer .iconContainer {
  visibility: hidden;

}

.elementContainer:hover .iconContainer {
  visibility: visible;
}

/* .labelIconContainer i:nth-child(2n+1){
  color:#ff7200;
}
.labelIconContainer i:nth-child(2n){
  color:gray;
} */

.elementContainer .text-field,
.elementContainer .date-field,
.elementContainer .select-field,
.elementContainer .file-field,
.elementContainer .textarea-field,
.elementContainer .number-field {
  padding: 9px 15px;
  margin: 8px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.widthAtCreation {
  width: 100%!important;
}

.elementContainer .text-field::placeholder,
.elementContainer .date-field::placeholder,
.elementContainer .select-field::placeholder,
.elementContainer .file-field::placeholder,
.elementContainer .textarea-field::placeholder,
.elementContainer .number-field::placeholder {
  font-size: .8rem;

}

.default-select-field {

  width: 100%;
  padding: 7px 15px;
  margin: 8px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.default-select-field::placeholder {
  font-size: .8rem;
  color: grey;

}

.inputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-collapse: collapse;
}

.multifieldDelete {
  margin: 8px;
}

.multifieldDelete i {
  border: 2px solid #e75e2e;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  color: #e75e2e;
}

.elementContainer .textarea-field {
  min-height: 50px
}

.elementContainer .text-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .date-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .select-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .file-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .textarea-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .number-field:focus {
  border: 1px solid #e75e2e;
}

.elementContainer .paragraphIconContainer,
.elementContainer .headingIconContainer {
  float: right;

}

.elementContainer .radio-field,
.elementContainer .checkbox-field {
  font-size: 1.1rem;
  margin: 0 10px 0 0;


}

input[type="checkbox"] {
  margin: 0 0 0 7px;
}

.radioWrapper,
.checkboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.elementContainer .GridIconContainer {

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.paragraphIconContainer i,
.headingIconContainer i,
.GridIconContainer i {
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0 0 0 10px;
}

.elementContainer .paragraph {
  font-size: 15px;
  color: #3e3939;
  text-align: center;
}

.elementContainer .paragraphContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.elementContainer .headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 3px 0px;
  /* padding: 0 0 25px 0px; */
  /* margin: 8px 0; */
}

.elementContainer .headingContainer h5 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  color: #8f7c7c;
  margin: 0;

}

.elementContainer .headingContainer span {

  border-bottom: 2px solid #8f7c7c;
  letter-spacing: 2px;
}

.selectOpations {
  background-color: rgb(255, 255, 255) !important;
}

.selectOpations:hover {
  background-color: #dddddd !important;
  color: gray;
}

.grid_cont {
  display: flex;
  flex-wrap: wrap;
}

.item:empty {
  border: 1px solid #eaeaea;
}

.item {
  flex: 1 0 300px;
  /*150px*/
  min-height: 20px;
  /* min-height: 50px; */
  margin: 5px;
  width: 100%;
  position: relative;
  top:-10px
}


.items {
  flex: 1 0 300px;
  /*150px*/
  min-height: 50px;
  /* min-height: 50px; */
  /* width:100%; */
  position: relative;
}

.hideElement {
  display: none;
}

.hideVisibility {
  visibility: hidden;
}

.excelDownloadButton {
  cursor: pointer;
  border: none;
  color: grey;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15), 0 1px 3px 1px rgba(0, 0, 0, .15) !important;
}



/* edit model css start*/
.modalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;

}

.modalContainer {
  width: 65%;
  /* max-height: calc(100% - 100px); */
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* display: flex;
  flex-direction: column; */
  z-index: 1000;

}

.modelHeader {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 2px 15px 0 15px;
  margin: 0;
  border-bottom: 1px solid rgb(194, 189, 189);
}

/* .modalContainer .title{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
} */
.modalContainer .title label {
  color: rgb(0, 0, 0);
  /* position: relative;
  top:-26px; */
  font-size: 1.3rem;
}

/* .titleCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: flex-end;
} */

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: rgb(0, 0, 0);

}

.modalContainer .body {
  flex: 50%;
  background-color: white;
  margin: 0 10px 10px 10px;
  padding: 20px;
  color: gray;
  max-height: 68vh;
  overflow-y: auto;
}

.productTypeModel .body {
  flex: 50%;
  background-color: white;
  margin: 0 10px 10px 10px;
  padding: 2px 20px 20px 20px;
  color: gray;
  max-height: 83vh;
  overflow-y: auto;
}

.formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
}

.optionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
}

.optionRow .fa-minus-circle {
  cursor: pointer;
  color: #e75e2e;
  padding: 5px;
  font-size: 1.5rem;
}

.optionRow .fa-plus-circle {
  color: grey;
  cursor: pointer;
  padding: 5px;
  font-size: 1.5rem;
}

.form-element {
  margin: 0 0 10px 0;
}

.form-element .text-field,
.form-element .date-field,
.form-element .select-field,
.form-element .file-field,
.form-element .textarea-field,
.form-element .number-field {
  width: 100%;
  padding: 9px 15px;
  margin: 8px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.productTypeModel .text-field,
.productTypeModel .date-field,
.productTypeModel .select-field,
.productTypeModel .file-field,
.productTypeModel .textarea-field,
.productTypeModel .number-field {
  min-width: 120px;
  max-width: 100%;

  padding: 9px 15px;
  margin: 8px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.form-element .text-field:focus {
  border: 1px solid #e75e2e;
}

.form-element .date-field:focus {
  border: 1px solid #e75e2e;
}

.form-element .select-field:focus {
  border: 1px solid #e75e2e;
}

.form-element .file-field:focus {
  border: 1px solid #e75e2e;
}

.form-element .textarea-field:focus {
  border: 1px solid #e75e2e;
}

.form-element .number-field:focus {
  border: 1px solid #e75e2e;
}

.columnFirst,
.columnSecond {
  flex: 1 0 460px;
  width: 100%;
  position: relative;

}

.columnThird {
  flex: 1 0 30px;
  width: 100%;
  position: relative;

}

.form-element .fa-exclamation-circle {
  color: #e75e2e;
  font-size: 1.1rem;
}

.minMaxWarapper,
.optionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-collapse: collapse;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 280px;
  background-color: #cac5c5;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  padding: 12px 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: .8rem;
  font-family: 'Nunito', sans-serif;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 19%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*edit model css end*/


/* Add Data To Form  css start*/
.AddDataToForm {
  max-height: 76vh;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}

/* Add Data To Form  css end*/


/* Excel upload css start*/

/* .excelUploadWrapper .excelUploadInput input{
  width: 100%;
  padding: 7px 15px;
  margin: 8px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
} */
.excelUploadInput input {
  width: 220px;
  padding: 4px 9px;
  margin: 0 0 0px 0;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

/*Excel upload css end*/

/* job management css start*/

.endJobButton {
  border: none;
  color: white;
  padding: 0x 0px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1rem !important;
  border-radius: 3px;
  margin: 0
}

.endJobButton i {
  padding: 1px 3px;
}

.endJobButton {
  background-color: #e75e2e;
}


.ant-input-search-enter-button+.ant-input-group-addon,
.ant-input-search-enter-button input+.ant-input-group-addon {
  padding: 0;
  border: 0;
  position: relative;
  top: -8px;
}

.ant-select-selection--multiple {
  white-space: nowrap;
  max-height: 40vh;
  overflow: auto
}

.gx-card {
  margin-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  border-radius: 10px;
}
.ant-table-body { overflow-x: auto !important}


/*job management css start*/

/*Dynamic form css end*/


/*this is for bonded and domestic dashboard model css when printing into pdf*/
@media print {
  .single-check {
    page-break-inside: avoid;
    page-break-before: always;
    /* padding-bottom: 140px!important; */
  }

}

@page {
  /* size: A4; */
  margin-top: 20px;
  margin-bottom: 20px;
}

/*shipping page css */
@media screen and (max-width: 992px) {
  .column {
    flex: 50% !important;
  }
}

@media screen and (max-width: 578px) {
  .column {
    flex: 100% !important;
  }
}

@media screen and (max-width:766px) {
  .card-size {
    width: 80vw;
  }
}

.css-21z6bt-control {
  border-color: #e75e2e!important;
  box-shadow: 0 0 1px #e75e2e!important;
}
.div-2col {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.div-topdown {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.div-showside {
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  position: relative;
  width: 97%;
}

.add-grid-btn {

  border: none;
  border-radius: 4px;
  bottom: 5px;
  color: #fff;
  cursor: pointer;
  font-size: .7rem;


  right: 3px;
}

.gx-mt-22 {
  margin-top: 22px;
}

.width-75 {
  width: 75%
}

.div-showside-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #f0f0f0;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  position: relative;
  width: 50%;
}

.detail-c1 {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  text-align: flex-start;
  width: 25%;
}

.floor-detail-c1 {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  text-align: flex-start;

}

.floor-detail-c2-m {
  align-items: center;
  justify-content: right;
  display: flex;
  height: 100%;
  position: relative;
  width: 50%;
  border-right: 2px dashed grey;

}

.floor-detail-c2 {
  justify-content: right;
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 50%;
}

.detail-c2 {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 75%;
}

.detail-c1,
.floor-detail-c1>span {
  color: #000;
  font-size: .8rem;
  font-weight: 600;
}

.detail-c2,
.floor-detail-c2-m>span {
  background-color: initial;
  border: none;
  color: #ff7200;
  font-size: .8rem;
  font-weight: 700;
  width: 300px !important;
}

.floor-detail-c2>span {
  background-color: initial;
  border: none;
  color: #ff7200;
  font-size: .8rem;
  font-weight: 700;
  width: 300px !important;
}

.width-30 {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 94%;
}

.row-element-1,
.row-element-2 {
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  height: 70%;
  text-align: left;
}

.row-element-2 {
  width: 48%
}

.width-30-m {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 94%;

}

.view-Inrows {
  display: flex;
  position: relative;
  width: 100%;
}

.view-element {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px dashed grey;
  margin-bottom: 6px;
}

.detail-row1 {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 94%
}



.grid-input {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 6vh;
  align-items: center;
}

.grid-input-fix {
  position: fixed
}

#inpt-v {
  color: #000;
  border: 1px solid e0e0e0;
  border-radius: 5%;
  height: 35px;

}

#inpt-v2 {
  color: #000;
  border: 1px solid e0e0e0;
  border-radius: 5%;
  height: 35px;
  width: 60px;
}

.gx-m-60 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 70vh;
}

.plain-grid {
  background: #FFF;
  color: #000
}

.color-grid {
  background: #ff7200;
  color: #000
}

.copy-updn {
  display: flex;
  flex-direction: column;
}

.inside-grid {
  cursor: pointer;
  width: 35%;
  margin-left: 33%;
}


.inside-grid:hover {
  text-decoration: underline;
  font-weight: 800;
  color: green;
}

.row-inpt-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.td-relative {
  position: relative;
}

.top-left-div {
  position: absolute;
  top: 0;
  left: 0;
}

.row-inpt-grid2 {
  display: flex;
  flex-direction: row;

  margin-bottom: 15px;
}

.label-inpt-row {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;

}

.td-head-name {
  font-weight: bold;
  font-size: larger;
  cursor: pointer;
}

.gx-mt-16 {
  margin-top: 18px;
}



.thead-div {
  border: 1px solid grey;
  width: 15%;
}

.thead-div2 {
  border: 1px solid grey;
  width: 20%;
}

.gx-flex-column-360 {
  display: flex;
  flex-direction: column;
  width: 360%;
}

.thead-div-70 {
  border: 1px solid grey;
  width: 70%;
}

.thead-div-71 {
  border: 1px solid grey;
  width: 71%;
}

.thead-div-72 {
  border: 1px solid grey;
  width: 72%;
}

.div-over {
  position: absolute;
  z-index: 4;
  margin-left: 87%;
}

.gx-mt-15 {
  margin-top: 15px;
}

.gx-alse {
  align-self: end;
}

.gx-df-row {
  display: flex;
  flex-direction: column;
  width: fit-content !important
}

.gx-t-s {
  font-size: small;
}

.div-df-aib {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  /* align-items: baseline; */
}

.div-30-col {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 10px;
}

.div-btn-17 {
  margin-top: 17px
}
.newDash{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 90%;
    height: 20vh;
    margin-top: 3vh;
    margin-left: 6vh;
    background: gainsboro;
}

.dash-head{
  height: 30%;
  width: 15%;
  border: 1px solid black;
}
.dash-head-full{
  width: 100%;
  height:100%;
}
#dash-new-table{
  width: 90%;
  border:1px solid #000000;
}
.dashboard-heads th{
  background: rgb(104, 98, 98);
  font-size: .8rem!important;
}
.content-table td{
  color: #000;
  font-size: .8rem!important;
}

.dash-body-row{
  height: 5vh;
  height:calc(var(--var-height)*85px);
  min-height: calc(var(--var-height)*85px);
}
#custom-td-c{
  text-align: center;
}

.label-t-10 {
  padding-top: 10px;
}

.grid-mt-10 {
  margin-top: 10px;
}

.vari-width {
  /* min-width:85px; */
  min-width: calc(var(--var-width)*85px);
  width: calc(var(--var-width)*85px);
}

.vari-height {
  height: calc(var(--var-height)*85px)
}



.div-table {
  display: table;
  width: 85%;
  background-color: #eee;
  border: 1px solid #666666;
  border-spacing: 5px;
}

.tr {
  display: table-row;
}

.tc {
  display: table-cell;
  vertical-align: top;
  background-color: #ccc;
  padding: 5px 10px;
}

.tc1 {
  width: 240px;
}

.tc4,
.tc5,
.tc6 {
  width: 80px;
}

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.web-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
}

.side-margin {
  margin: 0 50px;
}

.web-back1 {
  background-color: #f9f1ef;
  padding-bottom: 3em;
  width: 100%;
}

.main-website {
  display: flex;
  flex-direction: column;
}

.back2-sec {
  justify-content: space-between;
  align-items: center;
}

.back-sec2 {
  justify-content: space-between;
  align-items: baseline;
}

.web-spl-txt2 {
  font-size: 3vw;
  font-family: 'Fjalla One', sans-serif;
  color: #706c6c;
}

.web-back2 {
  align-items: center;
  padding: 3em 0;
  gap: 3em;
  /* height: 50vh; */
  /* border: 1px solid red; */
  /* padding-bottom: 3em; */
}

.web-back2>* {
  flex-basis: 100%;
}

.top-margin {
  margin-top: 30px;
}

.pos-rel {
  position: relative;
}

.web-img1 {
  width: 74%;
}

.pos-abs {
  position: absolute;
}

.dot-blc {
  left: -17%;
  bottom: 0%;
  width: 14%;
}

.dot-trc {
  width: 14%;
  top: 0%;
  right: 6%;
}

.dot-whole {
  width: 43%;
  margin-right: -25px;
}

.dot-whole2 {
  flex-basis: 100%;
  /* top: 50px; */
  /* left: -15px; */
}

.web-img2 {
  z-index: 10;
  width: 100%;
  top: 20px;
}

.dot1-img2 {
  left: 2%;
  top: 7%;
  z-index: -10;
  /* height: 40%; */
}

.dot2-img2 {
  left: 33%;
  top: 49%;
  /* height: 40% */
  z-index: -10;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.web-heading2 {
  font-family: 'Fjalla One', sans-serif;
  color: #e75e2e;
}
.web-heading2-w{
  font-family: 'Fjalla One', sans-serif;
  color: #fff;
}
.web-sec2-txt {
  flex-basis: 100%;
  justify-content: flex-start;
}

.web-detail1 {
  font-family: 'Montserrat', sans-serif;
  color: #000;
}

.back-img {
  object-fit: cover;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.web-sec3 {
  /* height: 30vh; */
  /* background: rgb(240, 113, 22); */
  background-image: linear-gradient(rgba(240, 113, 22, 0.8), rgb(240, 113, 22, 0.8)), url('../assets/images/Background\ image.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: 4em;
}
.contact-sec1{
  background-image: url('../assets/images/bg.png');
  background-size: cover;
  background-position: center;
  height: 20vw;
  position: relative;
}
.contact-heading{
  position: absolute;
  bottom: -22%;
  height: 12vw;
}
.img-over-half{
  width: 100%;
  height: 100%;
}

.sec3-img {
  width: 40%;
  position: absolute;
  top: 5em;
  right: 2em;
}
.web-sec3-img{
  width: 100%;
}
.aboutUs-txt{
  font-size:1.3vw;
  margin-top: 7em;
}
.aboutUs-txt-foot{
  font-size:1.3vw;
}

.web-heading3 {
  font-family: 'Fjalla One', sans-serif;
  color: #fff;
}

.web-detail2 {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  margin-top: 1vh;
  margin-bottom: 1vh;
  /* color: black; */
}

.web-sec3-container {
  /* position: relative; */
  /* top: -180px; */
  padding: 2vh 0;
  flex-basis: 65%;
}
.flex-basis30{
  flex-basis: 30%;
}
.accordian {
  flex-basis: 100%;
}

.accordian .contentBx {
  position: relative;
  margin: 10px 20px;
}

.accordian .contentBx .label-web {
  position: relative;
  padding: 10px;
  background-color: #e0e0e0;
  font-size: 1.6vw;
  color: #e75e2e;
}
.accordian .contentBx .label-web2 {
  position: relative;
  padding: 10px;
  background-color: #e0e0e0;
  font-size: 1.6vw;
  color: #000;
}

.accordian .contentBx .label-web::before {
  content: "\02C5";
  position: absolute;
  top: 50%;
  right: 20px;
  transition: 0.5s;
  transform: translateY(-50%);
}
.accordian .contentBx .label-web2::before{
  content: "\02C5";
  position: absolute;
  /* top: 50%; */
  right: 20px;
  transform: translateY(-50%);
  transition: 0.5s;
  transform: rotate(180deg);
}
.accordian .contentBx .content-web {
  position: relative;
  background: #fff;
  color: #000;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  overflow-y: auto;
}


  .accordian .contentBx .content-web2 {
  height: 8vw;
  padding: 10px;
  position: relative;
  background: #fff;
  color: #000;
  overflow: hidden;
  transition: 0.5s;
  overflow-y: auto;
}

.logo-size {
  width: 23%;
}

.web-logo-grid {
  max-width: 92%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); */
  /* grid-auto-rows: 6rem; */
}
.about-logo-grid{
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5,1fr);
}
.about-card{
  display: flex;
  align-items: center;
  border: 1px solid grey;
  padding: 1em;
}
.web-card {
  border: 1px solid grey;
  color: #000;
}

.web-card>img {
  display: block;
  max-width: 100%;
}
.web-clients{
  font-family: 'Fjalla One',sans-serif;
  color: #e75e2e;
  margin: 1.5em 0em;
  font-size: 3vw;
}
.icon-height{
  height: 10vw;
}
.last-icon-height{
  height: 5vw;
}
.web-contact{
  margin-left: -1em;
}
.web-flex{
  display: flex;
  /* flex-direction: column; */
}
.web-flex-reverse{
  display: flex;
  margin: 0px 2%;
}
.web-navbar{
  color:black;
  width:15em;
}
.main-website h1{
  font-size: 3vw;
}
.main-website p{
  font-size: 1.3vw;
}
.web-login{
  color: #fff;
  background-color: #8d8d91;
  display: flex;
  gap: 1vh;
  padding: 0.5vh 2vh;
  border-radius: 5px;
}
.login-logo{
  border-right: 1px solid white;
  padding-right: 1vh;
}
.web-login:hover{
  cursor: pointer;
}
.web-login:hover{
  background-color: #3e3939;
}
.web-spl-txt {
  font-family: 'Fjalla One', sans-serif;
  font-size: 4vw;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #e75e2e;
  margin: 0%;
}
.web-flex3{
  display: flex;
}
.web-ul{
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  color: #000;
  align-items: center;
}
.web-ul li{
  padding: 18px 10px;
}
.web-ul2{
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  color: #000;
  align-items: center;
}
.web-ul2 li{
  padding: 18px 10px;
}

.web-burger{
  width: 2rem;
  height: 3vw;
  z-index: 20;
  display: none;
}
.web-burger .head-line1{
  transform: rotate(0);
}
.web-burger .head-line2{
  transform: rotate(0);
}
.web-burger .head-line3{
  transform: rotate(0);
}
.web-burger .headline1{
  transform: rotate(40deg);
}
.web-burger .headline2{
  transform: translateX(100%);
  opacity: 0;
}
.web-burger .headline3{
  transform: rotate(-40deg);
}
.web-burger .head-line1,.head-line2,.head-line3{
  width: 3.5vw;
    height: 0.5vw;
    /* background-color: ${({ open }) => open ? '#ccc' : '#333'}; */
    background-color: #333;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
}
.web-burger .headline1,.headline2,.headline3{
  width: 3.5vw;
  height: 0.5vw;
  /* background-color: ${({ open }) => open ? '#ccc' : '#333'}; */
  background-color: #ccc;
  border-radius: 10px;
  transform-origin: 1px;
  transition: all 0.3s linear
}
.contact-left{
  flex-basis: 100%;
}
.contact-right{
background-color: #ff7200;
flex-basis: 100%;
padding-top: 2%;
display: flex;
flex-direction: column;
justify-content: center;
}
.contact-inpts{
  width: 90%;
  margin-bottom: 1vw;
}
.contact-inpts-box{
  height: 9vw;
}
.contact-img-div{
height: 6em;
}
.contact-mid{
  margin-top: 11vw;
  display: flex;
  justify-content: space-between;
}
.contact-button{
  background-color: #ff7200;
  color: #fff;
}
.contact-foot{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vw;
}
.input-btn{
  width: 90%;
  display: flex;
  justify-content: flex-end;
}
.contact-logo{
  height: 100%;
  filter: invert(1);
  width: 100%;
}
.contact-clients{
  width: 25%;
}
.about-basis-4{
  flex-basis: 40%;
}
.about-basis-6{
  flex-basis:50%
}
.about-center{
  align-self: center;
}

@media (max-width: 768px) {
  .web-burger {
    z-index: 20;
    width: 2rem;
    height: 3vw;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  .web-ul {
    flex-flow: column nowrap;
    background-color: #706c6c;
    position: fixed;
    transform: translateX(100%);
    top: 75px;
    right: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;
    transition: transform 0.5s ease-in-out;
    z-index: 10;
    /* display: none; */
  }

  .web-ul2 {
    flex-flow: column nowrap;
    background-color: #706c6c;
    position: fixed;
    transform: translateX(0);
    top: 75px;
    right: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;
    transition: transform 0.5s ease-in-out;
    z-index: 10;
  }
}
/* @media(min-width:950px) {
  .web-flex{
    display: flex;
    flex-direction: row;
  }
  .web-navbar{
    width: 26%;
  }
  
} */
@media (max-width:950px) {
  .dot-whole{
    margin-left: 2em;
    width: 43%;
  }
  .web-flex-reverse{
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
@media(max-width:600px) {
  .dot-whole{
    margin-left: 0em;
    width: 43%;
  }
  .side-margin{
    margin: 0px;
  }
  .web-navbar{
    color: #000;
    width: 58%;
  }
  .main-website{
    gap: 0;

  }
  .aboutUs-txt{
    margin-top: 8vw;
    margin-bottom: 4vw;
  }
  .web-flex{
    display: flex;
    flex-direction: column;
  }
  .web-logo-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .sec3-img {
    width: 65%;
    position: static;
    margin-left: 15%;
  }
  .web-flex3{
    display: flex;
    flex-direction: column-reverse;
  }
  .web-img2{
    width: 65%;
    margin-left: 15%;
  }
  .web-sec3{
    margin-bottom: 0px;
  }
  .main-website h1{
    font-size: 1.2rem !important;
  }
  .web-clients {
    font-size: 1.2rem;
  }
  .main-website p{
    font-size: 0.75rem;
  }
  .aboutUs-txt{
    font-size: 0.75rem;
  }
  .aboutUs-txt-foot{
    font-size: 00.75rem;
  }
  .accordian .contentBx .label-web2{
    font-size: 0.95rem;
  }
  .accordian .contentBx .label-web{
    font-size: 0.95rem;
  }
  h5.web-spl-txt{
    font-size: 10.2vw;
  }
  h2.web-spl-txt2{
    font-size: 6vw;
  }
  .accordian .contentBx .content-web2{
    height: 60px;
  }
  .contact-mid{
    flex-direction: column;
  }
  height{
    height: 20vw;
  }
  .last-icon-height{
    height: 6vw;
  }
  .web-detail-logo{
    gap: 1em;
  }
}

@media(min-width:1200px){
  .main-website h1{
    font-size: 2rem;
  }
  .web-clients{
    font-size: 2rem;
  }
  .main-website p{
    font-size: 0.85rem;
  }
  .aboutUs-txt{
    font-size: 0.85rem;
  }
  .aboutUs-txt-foot{
    font-size: 0.85rem;
  }
  .dot-whole2{
    flex-basis: 85%;
  }
  .sec3-img{
    width: 36%;
  }
  .web-sec3{
    padding:2% 0%
  }
  .accordian .contentBx .content-web2{
    height: 90px;
  }
}
@media(min-width:2000px){
  .main-website p{
    font-size: 1.85rem;
  }
  .main-website h1{
    font-size: 4rem;
  }
  .accordian .contentBx .content-web2{
    height: 190px;
  }
}
.webpage-wrapper{
  height: 100vh;
  overflow-y: auto;
}
.web-head-nav{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(142,142,142,.2),0 1px 1px 0 rgba(243,243,243,.14),0 2px 1px -1px rgba(204,204,204,.12)!important;

}




/*react-pdf css start*/

.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/*react-pdf css end*/


.map-container {
  height: 400px;
  }
  
  .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    max-width: 60vw;
    border-radius: 4px;
  }
  
  .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-dr ag-pan,
  .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-dr ag-pan .mapboxgl-canvas {
    touch-action: none;
    margin-top: 3px;
  }
  
  .gx-main-content-wrapper {
    padding: 37px 32px 0;
    flex: 1 1;
  }
  
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  
  .distance-container {
    position: absolute;
    top: "";
    left: 32px;
    z-index: 1;
  }
  
  .distance-container>* {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 11px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  #menu {
    position: absolute;
    padding: 10px 8px;
    z-index: 10;
    color: #151515;
    font-family: 'Nunito', sans-serif !important;
  }
  
  .calculation-box {
    height: 75px;
    width: 150px;
    color: #8f7c7c;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    text-align: center;
    border: 1px solid grey;
    position: relative;
    top: -81px;
    left: 6px;
  }